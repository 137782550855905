@import "../../../styles/_variables.scss", "../../../styles/media";

.divider-container {
  background-color: $color-primary;

  background: $color-primary center no-repeat;
  background-image: url("../../../assets/img/john-towner-bridge.jpg");
  background-image: url("../../../assets/img/john-towner-bridge.webp");
  background-size: cover;

  height: 550px;

  div {
    max-width: $max-width-container;
    margin: 0 auto;

    height: 100%;
    p {
      margin: 0;

      color: $color-white;

      font-size: $h1-fs-mobile;
      @include mq-md {
        font-size: $h1-fs;
      }
      line-height: $h1-lh;
      font-weight: $bold-fw;
      text-transform: uppercase;

      max-width: calc(100vw - $gutter-mobile * 2);
      margin: 0 auto;

      height: 100%;

      position: relative;
      > span {
        position: absolute;

        $vertical-offset: 40px;
        $horizontal-offset: $gutter-mobile;
        $horizontal-offset: $gutter;
        &:nth-child(1) {
          left: $gutter-mobile;
          @include mq-md {
            left: $horizontal-offset;
          }
          top: $vertical-offset;
        }
        &:nth-child(2) {
          right: $gutter-mobile;
          @include mq-md {
            right: $horizontal-offset;
          }
          bottom: $vertical-offset;
          text-align: right;
        }
        max-width: 300px;
        @media (min-width: 450px) {
          max-width: none;
        }
      }
    }
  }
}
