@import "../../styles/media", "../../styles/variables", "../../styles/mixins";

.footer-contact {
  margin-top: 40px;

  @include mq-md {
    margin-top: 0;
  }

  ul {
    li {
      > a,
      > button,
      > p {
        display: flex;
        align-items: baseline;
        margin-top: 15px;
        font-size: $std-fs;
        img {
          margin-left: 10px;
          width: 24px;
        }
      }

      &:last-of-type {
        min-height: 31px;
      }
    }
  }
}
