@import "../../../styles/variables", "../../../styles/media";

.projects-container {
  background-color: $color-white;

  padding: $section-padding-mobile;
  @include mq-md {
    padding: $section-padding;
  }

  h2 {
    margin-bottom: 70px;
  }

  > div {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    > p {
      margin-bottom: 10px;
      @include mq-md {
        margin-bottom: 50px;
      }
    }

    .projects-list-container {
      > li {
        > div {
          width: 100%;

          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-direction: column;

          @include mq-md {
            flex-direction: row;
          }

          &.even {
            @include mq-md {
              flex-direction: row-reverse;
            }
          }

          > :first-child {
            @include mq-md {
              margin-right: 60px;
              margin-left: 30px;
            }
          }
          &.even {
            > :first-child {
              @include mq-md {
                margin-right: 30px;
                margin-left: 60px;
              }
            }
          }

          > span {
            font-family: "Montserrat", sans-serif;
            font-size: $h1-fs;
            font-weight: $bold-fw;
            > span {
              color: $color-primary-tint;
            }
            margin-bottom: 20px;
            margin-top: 35px;
            @include mq-md {
              margin-bottom: 0;
              margin-top: 0;
            }
          }
          > p {
            max-width: 600px;
          }
        }
        > img {
          display: none;
          @include mq-md {
            display: block;

            width: 150px;
            object-fit: contain;

            margin: 40px auto;
          }
        }
      }
    }
  }
}
