@import "../../../styles/media";

.services-industry-item {
  flex: 1;

  padding: 0 20px;

  text-align: center;

  img {
    width: 80px;
    height: auto;
    object-fit: contain;

    margin-bottom: 20px;
    @include mq-md {
      margin-bottom: 40px;
    }
  }
}
