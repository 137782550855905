@import "../../../styles/variables", "../../../styles/media";

.projects-container {
  background-color: $background-primary-light;

  padding: $section-padding-mobile;
  @include mq-md {
    padding: $section-padding;
  }

  h2 {
    margin-bottom: 70px;
  }

  > div {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    > p {
      margin-bottom: 10px;
      @include mq-md {
        margin-bottom: 50px;
      }
    }

    :global(.rec-carousel-wrapper) {
      margin-top: 24px;

      :global(.rec-arrow) {
        background-color: transparent;
        box-shadow: none;
        color: $color-grey-darker;

        transition: all 0.3s;

        &:hover:not(:disabled) {
          color: $color-primary;
          transform: scale(1.25);
        }

        &:disabled {
          color: $color-grey-lighter;
        }
      }

      :global(.rec-dot) {
        box-shadow: none;
        background-color: $color-grey-lighter;

        transition: all 0.3s;

        &:global(.rec-dot_active) {
          background-color: $color-primary;
          transform: scale(1.25);
        }

        &:hover {
          transform: scale(1.25);
        }
      }

      :global(.rec-carousel-item) {
        display: flex;
        justify-content: center;
        align-items: center;

        :global(.rec-item-wrapper) {
          position: relative;

          > div {
            display: flex;
            flex-direction: column;
            justify-self: center;
            align-items: center;

            padding: 0px 25px !important;
            @include mq-md {
              padding: 0px 50px !important;
            }

            .title {
              font-size: $std-fs;
              align-self: flex-end;
            }

            .description {
              margin-bottom: 32px;

              > p {
                font-size: $small-fs;
                color: $color-grey-darker;

                &:not(:last-child) {
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
