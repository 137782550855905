@import "../../../styles/variables", "../../../styles/media";

.show-more-link {
  display: none;
  @media (min-height: 1000px) {
    display: block;
  }
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  a {
    display: flex;
    flex-direction: column;

    color: $color-primary;
    font-weight: $bold-fw;
    text-transform: uppercase;
    text-align: center;
    img {
      width: 30px;
      object-fit: contain;

      display: block;
      margin: 10px auto;

      animation: 1.5s bounceAnimation infinite ease-out;

      @keyframes bounceAnimation {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-10px);
        }
        100% {
          transform: translateY(0);
        }
      }
    }
  }
}
