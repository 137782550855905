p,
a,
button {
  font-size: $std-fs;
  line-height: $std-lh;
  font-weight: 400;
}

h1,
.h1 {
  font-family: "Montserrat", sans-serif;
  font-size: $h1-fs-mobile;
  line-height: $h1-lh-mobile;
  @include mq-md {
    font-size: $h1-fs;
    line-height: $h1-lh;
  }
  font-weight: $bold-fw;
  text-transform: uppercase;
  letter-spacing: -1px;
}

h2,
.h2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  font-size: $h2-fs;
  line-height: $h2-lh;
  font-weight: 400;
  letter-spacing: 2px;
  border-bottom: 2px solid $color-primary;
  margin-bottom: 27px;
}

h3 {
  font-size: $h3-fs;
  line-height: $h3-lh;
  color: $color-primary;
  font-weight: $bold-fw;
  margin-bottom: 20px;
}
