/* Colors */
$color-primary: #4a66ac;
$background-primary-light: #f0f5ff;
$color-primary-tint: #7184b6;
$color-white: #fff;
$color-black: #1d1d1d;
$color-grey: #8f8f8f;
$color-grey-darker: #5a5a5a;
$color-grey-lighter: #c5c5c5;

/* Typography */
$h1-fs: 54px;
$h1-fs-mobile: 36px;

$h1-fs-bigger: 64px;
$h1-fs-bigger-mobile: 40px;

$h1-fs-smaller: 44px;
$h1-fs-smaller-mobile: 30px;

$h2-fs: 33px;
$h3-fs: 20px;
$std-fs: 18px;
$small-fs: 16px;
$button-fs: 15px;

$std-lh: 26px;
$h1-lh: 70px;
$h1-lh-mobile: 40px;
$h2-lh: 40px;
$h3-lh: 28px;

$bold-fw: 700;

/* UI */
$button-border-radius: 54px;
$max-width-container: 1500px;
$gutter-mobile: 24px;
$section-padding: 200px 50px;
$section-padding-mobile: 100px $gutter-mobile;
$button-box-shadow: 0px 0px 1.25rem 0px rgb(29 29 29 / 10%);
$gutter: 50px;
