@mixin link-hover-effect($size: 2px, $color: white) {
  li,
  a {
    background-image: linear-gradient(270deg, $color, $color);
    background-size: 0 $size;
    transition: background-size 0.6s cubic-bezier(0.22, 1, 0.36, 1);
    padding-top: 2px;
    padding-bottom: 2px;
    background-repeat: no-repeat;
    background-position: 100% 100%;
  }

  li:hover,
  a:hover {
    background-size: 100% $size;
    background-position: 0 100%;
  }

  li.active {
    background-size: 100% $size;
  }
}

@mixin sr-only() {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@mixin primary-button($hasBorder: false) {
  border-radius: $button-border-radius;
  box-shadow: $button-box-shadow;
  padding: 14px 27px;
  font-weight: $bold-fw;
  text-align: center;
  cursor: pointer;

  @if $hasBorder {
    background-color: $background-primary-light;
    border: 2px $color-primary solid;
    color: $color-primary;
  } @else {
    background-color: $color-primary;
    color: $color-white;
  }
}
