@import "../../../styles/variables";

.services-intro-container {
  max-width: 850px;

  margin: 0 auto;

  text-align: center;

  h2 {
    display: inline-block;
    margin-bottom: 70px;
  }
  > p {
    margin-bottom: 25px;
    &:first-of-type {
      font-size: 25px;
      line-height: 30px;

      margin-bottom: 36px;
    }
    span {
      color: $color-primary;
      font-weight: $bold-fw;
    }
  }
  ul {
      margin-top: 50px;
    li {
      p {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 15px;

        text-transform: uppercase;

        color: $color-primary;
        font-weight: 600;
        img {
          width: 20px;
          margin: 0 10px;
        }
      }
    }
  }
}
