$xs-breakpoint: 375px;
$sm-breakpoint: 576px;
$md-breakpoint: 768px;
$lg-breakpoint: 1024px;

@mixin mq-xs() {
  @media (min-width: $xs-breakpoint) {
    @content;
  }
}

@mixin mq-sm() {
  @media (min-width: $sm-breakpoint) {
    @content;
  }
}

@mixin mq-md() {
  @media (min-width: $md-breakpoint) {
    @content;
  }
}

@mixin mq-lg {
  @media (min-width: $lg-breakpoint) {
    @content;
  }
}

@mixin mq($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}
