* {
  box-sizing: border-box;
}

html {
  max-width: 100vw;
}

ul,
ol {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
  font-weight: 700;
  text-transform: capitalize;
}

ul,
ol,
p,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

select {
  border: 0;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

button {
  border: 0;
  margin: 0;
  padding: 0;
  box-shadow: none;
  background: none;
  color: inherit;
}
