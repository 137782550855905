@import "../../../styles/variables", "../../../styles/mixins",
  "../../../styles/media";

.services-section {
  background-color: $background-primary-light;

  padding: $section-padding-mobile;
  @include mq-md {
    padding: $section-padding;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    width: 175px;

    margin: 60px auto;
    @include mq-md {
      margin: 80px auto;
    }
    transform: rotate(185deg);
  }

  > .get-in-touch-link {
    margin-top: 100px;
    @include primary-button(true);
    > a {
      font-weight: $bold-fw;
    }
  }
}
