@import "../../styles/variables", "../../styles/media";

.footer-container {
  background-color: $color-black;

  padding: 50px $gutter-mobile;
  padding-bottom: $gutter-mobile;
  @include mq-md {
    padding: 100px 80px;
    padding-bottom: 20px;
  }

  color: $color-white;

  > .footer-max-container {
    max-width: $max-width-container;
    margin: 0 auto;

    > div {
      display: flex;
      flex-direction: column;

      @include mq-md {
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }
    aside {
      margin-top: 50px;

      display: block;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin-left: auto;
      > p {
        font-size: 10px;
        color: $color-grey-lighter;
        span {
          color: $color-primary-tint;
          font-weight: $bold-fw;
        }
      }
    }
  }
}
