@import "../../styles/mixins", "../../styles/variables", "../../styles/media";

.main-navigation-container {
  display: none;
  @include mq-md {
    display: block;
  }

  > ul {
    display: flex;
    align-items: baseline;
    justify-content: center;

    > li {
      @include link-hover-effect(2px, $color-primary);

      margin-right: $gutter-mobile;
      @include mq-lg {
        margin-right: $gutter;
      }

      > a {
        font-size: $small-fs;
        @include mq-lg {
          font-size: $std-fs;
        }
      }
    }
  }
}
