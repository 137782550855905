@import "../../../styles/variables", "../../../styles/media";

$min-height: 900px;
$min-height-mobile: 650px;
.hero-container {
  height: 100vh;
  min-height: $min-height-mobile;
  @include mq-md {
    min-height: $min-height;
  }
  overflow: hidden;

  display: flex;

  > * {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;
  }

  background-color: $background-primary-light;

  > .img-container {
    position: absolute;
    @include mq-md {
      position: static;
    }
    img {
      width: 100vw;
      height: max(100vh, $min-height-mobile);
      @include mq-md {
        height: max(100vh, $min-height);
      }
      object-fit: cover;
      @include mq-md {
        width: 100%;
      }
    }
  }

  > div {
    width: 100%;
    > .hero-content-container {
      max-width: 960px;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: $gutter-mobile;

      margin: 0 auto;
      @include mq-md {
        padding: 36px 50px;
      }

      position: relative;

      &:before {
        content: none;
        @include mq-lg {
          content: "";
        }
        width: 433px;
        height: 200px;

        background-image: url("../../../assets/svg/line-3.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;

        position: absolute;
        top: 20%;
        left: -200px;
      }

      h1 {
        text-align: center;

        position: relative;

        max-width: 350px;
        @include mq-md {
          max-width: none;
        }

        span {
          display: block;
          &:first-of-type {
            color: $color-primary;
            font-size: $h1-fs-bigger-mobile;

            margin-bottom: 10px;
            @include mq-md {
              margin-bottom: 25px;
              font-size: $h1-fs-bigger;
            }
          }
          &:not(:first-of-type) {
            font-size: $h1-fs-smaller-mobile;
            @include mq-md {
              font-size: $h1-fs-smaller;
            }
          }
          &:nth-of-type(2) {
            color: $color-primary-tint;
            @media (min-height: 650px) and (min-width: 375px) and (max-width: $md-breakpoint) {
              margin-bottom: 270px;
            }
            @media (min-height: 700px) and (min-width: $xs-breakpoint) and (max-width: $md-breakpoint) {
              margin-bottom: 400px;
            }
            @include mq-md {
              margin-bottom: 15px;
            }
          }
          &:nth-of-type(3) {
            color: $color-white;
            @include mq-md {
              color: $color-grey;
              margin-bottom: 15px;
            }
          }
          span:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
