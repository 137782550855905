@import "../../styles/variables";

.personal-info-container {
  p {
    &:last-of-type {
      margin-top: 0;
      font-size: 13px;
      color: $color-grey-lighter;
      letter-spacing: 1.6px;
    }
  }
  a {
    display: block;
    margin-top: 30px;
    img {
      margin-left: 5px;
    }
  }
}
