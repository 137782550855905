@import "../../styles/variables", "../../styles/media";

.header-container {
  position: absolute;
  @include mq-md {
    position: fixed;
  }
  top: 0;
  left: 0;
  z-index: 10;

  width: 100%;

  padding: $gutter-mobile;

  @include mq-md {
    padding: 18px 36px;
  }

  background-image: none;

  > div {
    max-width: $max-width-container;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .navigation-lang-selection-container {
      display: flex;
      align-items: baseline;
    }
  }
  &.scrolled {
    @include mq-md {
      background-image: linear-gradient(
        to top,
        rgba(255, 255, 255, 0),
        rgba(248, 250, 255, 1)
      );
    }
  }
}
