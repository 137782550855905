@import "../../../styles/variables", "../../../styles/media";

.myself-section {
  padding: $section-padding-mobile;
  @include mq-md {
    padding: $section-padding;
  }

  width: 100%;

  background-color: $color-white;

  .myself-content-container {
    max-width: 1100px;

    margin: 0 auto;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    @include mq-md {
      flex-direction: row;
      justify-content: space-between;
    }

    .myself-text-container {
      max-width: 480px;
      h2 {
        display: inline-block;
      }
      p {
        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }
    }

    img {
      width: 250px;
      min-width: 280px;
      margin-bottom: 80px;
      @include mq-md {
        margin: 0;
        margin-left: 50px;
        width: 300px;
      }
      @include mq-lg {
        width: 420px;
      }
      max-width: 100%;
      object-fit: contain;

      border-radius: 100%;
    }
  }
}
