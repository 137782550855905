@import "../../styles/variables";

.logo {
  font-size: 34px;
  font-weight: 300;

  color: $color-grey-darker;
  &.dark-bg {
    color: $color-grey-lighter;
  }
  span {
    color: $color-primary;
  }

  img {
    width: 24px;
    margin-right: 5px;
  }
}
