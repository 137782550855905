@import "./resets", "./media", "./variables", "./typography", "animate.css";

html {
  scroll-behavior: smooth;
}

main,
body {
  overflow-x: hidden;
  max-width: 100vw;
}

body {
  color: $color-black;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
nav > ul > li,
nav > ol > li {
  font-size: $button-fs;
}

select,
button,
a {
  cursor: pointer;
}

a {
  text-transform: none;
}
