@import "../../../styles/variables", "../../../styles/media";

.services-industries-container {
  width: 100%;
  max-width: $max-width-container;

  margin: 0 auto;

  ul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include mq-md {
      flex-direction: row;
    }
    > :not(:last-of-type) {
      margin-bottom: 80px;
      @include mq-md {
        margin-bottom: 0;
      }
    }
  }
}
