@import "../../styles/variables", "../../styles/mixins";

.language-select-container {
  label {
    @include sr-only();
  }

  select {
    @include primary-button();

    option {
      padding: 10px;
      background-color: $color-white;
      color: black;
    }
  }
}
